












































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Expertise } from "@/models/entities/expertise.interface";
import { Customer } from "@/models/entities/customer.interface";

const UserCustomersCard = () =>
  import(/* webpackChunkName: "UserCustomersCard" */ "./UserCustomersCard.vue");

const UserExpertiseCard = () =>
  import(/* webpackChunkName: "UserExpertiseCard" */ "./UserExpertiseCard.vue");

enum TabEnum {
  EXPERTISES = 1,
  CUSTOMERS = 2,
}
type UserTab = { id: TabEnum; label: string; icon: string };

@Component({ components: { UserCustomersCard, UserExpertiseCard } })
export default class UserTabsCard extends Vue {
  @PropSync("customersPage", { required: true, type: Number })
  customersPage$: number;
  @PropSync("search", { required: false, default: "", type: String })
  search$: string;
  @PropSync("selectedCustomers", {
    required: false,
    default: () => [],
    type: Array,
  })
  private selectedCustomers$: Customer[];
  @PropSync("expertiseIds", { required: false, default: () => [], type: Array })
  private expertiseIds$: number[];
  @Prop({ required: true, default: null, type: Array })
  private customers: Customer[];
  @Prop({ required: true, default: null, type: Array })
  private expertise: Expertise[];
  @Prop({ required: false, default: null, type: Number })
  private disableCheckbox: number;
  @Prop({ required: true, type: Boolean })
  private isNewUser: boolean;
  @Prop({ required: false, type: String })
  private userTypeId: string;
  @Prop({ required: false, default: 0, type: Number })
  private customersNr: number;

  private timeout = null;
  private onlySelected = false;
  private tab = TabEnum.EXPERTISES;
  private allTabs: UserTab[] = [
    { id: TabEnum.EXPERTISES, label: "Compagnie", icon: "building-store" },
    { id: TabEnum.CUSTOMERS, label: "Clienti", icon: "accounts" },
  ];

  private get tabs(): UserTab[] {
    if (this.userTypeId === "Audit") return this.allTabs;
    return this.allTabs.filter((tab) =>
      this.userTypeId === "Customer"
        ? tab.id === TabEnum.CUSTOMERS
        : tab.id === TabEnum.EXPERTISES
    );
  }

  private get selectedByTab(): unknown {
    return {
      1: this.expertiseIds$?.length ?? 0,
      2: this.selectedCustomers$?.length ?? 0,
    };
  }

  @Watch("userTypeId", { immediate: true })
  userTypeIdWatcher(id: string): void {
    if (id !== "Audit") {
      this.tab = id === "Customer" ? TabEnum.CUSTOMERS : TabEnum.EXPERTISES;
    }
  }

  private created() {
    if (!this.isNewUser) this.onlySelected = true;
  }

  onSearch(search: string): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      this.search$ = search;
      if (this.tab === TabEnum.CUSTOMERS) {
        this.customersPage$ = 1;
        await this.$nextTick();
        this.$emit("reload:customers");
      }
    }, 350);
  }

  toggleOnlySelected(): void {
    this.onlySelected = !this.onlySelected;
  }
}
